(function () {
  const options = {
    before_label: 'Бесплатная',
    after_label: 'Платная'
  };

  const container = $("#twentytwenty-1");

  if (container.length)
  {
    $("#twentytwenty-1 img").each(function () {
      if (this.complete)
      {
        $("#twentytwenty-1").twentytwenty();
      }
      else
      {
        $(this).on("load", function () {
          $("#twentytwenty-1").twentytwenty();
        });
      }
    });
  }
})();

$(".scope .text-container").tooltip({
  delay: 50,
  html: true
});

$(".button-collapse").sideNav();

$("button").each(function () {
  if ($(this).text().toLowerCase() === "попробовать бесплатно")
  {
    $(this).on("click", function () {
      $("#sign-up").modal("open");
    });
  }
});

$(".modal").modal();

$(".tabs-dynamic .tabs").tabs();

$(".tabs-dynamic .control-arrow.prev").click(function () {
  let tabs = $(".tabs-dynamic .tabs");
  let prev = tabs.find("a.active").parent().prev(".tab");

  if (prev.length === 0)
  {
    let tab = tabs.find(".tab");
    prev = $(tab[tab.length - 1]);
  }

  $(".tabs-dynamic .tabs").tabs("select_tab", $(prev).find("a").attr("href").substr(1));
});

$(".tabs-dynamic .control-arrow.next").click(function () {
  let tabs = $(".tabs-dynamic .tabs");
  let next = tabs.find("a.active").parent().next(".tab");

  if (next.length === 0)
  {
    let tab = tabs.find(".tab");
    next = $(tab[0]);
  }

  $(".tabs-dynamic .tabs").tabs("select_tab", $(next).find("a").attr("href").substr(1));
});

$(".parallax").parallax();

$(".articles-carousel").owlCarousel({
  items: 1,
  nav: true,
  loop: true,
  navText: [
    "",
    ""
  ]
});

$(".brands-carousel").owlCarousel({
  items: 6,
  margin: 10,
  autoplay: true,
  autoplayTimeout: 3000,
  loop: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 2
    },
    600: {
      items: 4
    },
    992: {
      items: 5
    },
    1200: {
      items: 6
    }
  }
});

$(".rslides").responsiveSlides({
  auto: true,             // Boolean: Animate automatically, true or false
  speed: 500,            // Integer: Speed of the transition, in milliseconds
  timeout: 4000,          // Integer: Time between slide transitions, in milliseconds
  pager: false,           // Boolean: Show pager, true or false
  nav: true,             // Boolean: Show navigation, true or false
  random: false,          // Boolean: Randomize the order of the slides, true or false
  pause: false,           // Boolean: Pause on hover, true or false
  pauseControls: true,    // Boolean: Pause when hovering controls, true or false
  prevText: "Previous",   // String: Text for the "previous" button
  nextText: "Next",       // String: Text for the "next" button
  maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
  navContainer: "",       // Selector: Where controls should be appended to, default is after the 'ul'
  manualControls: "",     // Selector: Declare custom pager navigation
  namespace: "rslides",   // String: Change the default namespace used
  before: function () {
  },   // Function: Before callback
  after: function () {
  }     // Function: After callback
});