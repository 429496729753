(function () {
  // dropdown menu

  $(".header .nav-wrapper > ul > .dropdown").each(function () {
    let ul = $(this).find("> ul");
    let timeout = null;

    $(this).mouseenter(function () {
      clearTimeout(timeout);
      $(this).addClass("opened");
      $(ul).stop(!0, !0).delay(100).slideDown(250);
    });

    $(this).mouseleave(function () {
      timeout = setTimeout(() => $(this).removeClass("opened"), 250);
      $(ul).stop(!0, !0).slideUp(250).removeClass("opened");
    });
  });

  $(".header .dropdown .dropdown").each(function () {
    let ul = $(this).find("> ul");

    $(this).mouseenter(function () {
      $(ul).stop(!0, !0).delay(100).fadeIn(250);
    });

    $(this).mouseleave(function () {
      $(ul).stop(!0, !0).fadeOut(250);
    });
  });

})();