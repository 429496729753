/**
 * Author: Profesor08
 */


(function () {
  function resizeContainer(container, height)
  {
    if (height && canResize())
    {
      container.css({
        height: height
      });
    }
    else
    {
      container.removeAttr("style");
    }
  }

  function canResize()
  {
    return $(window).width() > 975;
  }

  $(".opportunities").each(function () {
    const container = $(this).find(".collapsible-container");
    const handle = $(this).find(".collapsible-header");

    handle.on("click", function () {
      resizeContainer(container, $(this).next(".collapsible-body").height());
    });

    resizeContainer(container, $(this).find(".collapsible-header.active + .collapsible-body").height());

    window.addEventListener("resize", () =>
      resizeContainer(container, $(this).find(".collapsible-header.active + .collapsible-body").height())
    );
  });

})();