$.fn.circleDiagram = function (option) {

  const circleDiagram = function (canvas) {
    let ctx = canvas.getContext('2d');
    let lineWidth = canvas.getAttribute("data-line-width") || 2;
    let center = parseInt(ctx.canvas.width / 2 - lineWidth);
    let size = parseInt(ctx.canvas.width / 2 - lineWidth * 2);
    let animationDuration = canvas.getAttribute("data-animation-duration") || 2000;
    let value = parseInt(360 / 100 * canvas.getAttribute("data-progress-value") || 80);
    let textValue = parseInt(canvas.getAttribute("data-text-value")) || 0;
    let textSuffix = canvas.getAttribute("data-text-suffix") || "%";
    let textColor = canvas.getAttribute("data-text-color") || "%";
    let textFont = canvas.getAttribute("data-text-font") || "%";

    function animate(options)
    {
      let start = performance.now();
      let timing = function (timeFraction) {
        return 1 - Math.sin(Math.acos(timeFraction));
      };

      requestAnimationFrame(function animate(time) {
        // timeFraction от 0 до 1
        let timeFraction = (time - start) / options.duration;
        if (timeFraction > 1)
        {
          timeFraction = 1;
        }
        // текущее состояние анимации
        let progress = timing(timeFraction);
        options.draw(progress);
        if (timeFraction < 1)
        {
          requestAnimationFrame(animate);
        }
      });
    }

    let background = canvas.getAttribute("data-background-color") || "#FFFFFF";
    let barColor = canvas.getAttribute("data-bar-color") || "#CCCCCC";
    let progressColor = canvas.getAttribute("data-progress-color") || "#FF0000";

    function drawBackground()
    {
      ctx.beginPath();
      ctx.strokeStyle = background;
      ctx.lineWidth = 0;
      ctx.arc(center, center, size, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fillStyle = background;
      ctx.fill();
    }

    function drawBar()
    {
      ctx.beginPath();
      ctx.strokeStyle = barColor;
      ctx.lineWidth = lineWidth;
      ctx.arc(center, center, size, 0, 2 * Math.PI);
      ctx.stroke();
    }

    function drawProgress(progress)
    {
      ctx.beginPath();
      ctx.strokeStyle = progressColor;
      ctx.lineWidth = lineWidth;
      ctx.arc(center, center, size, -0.5 * Math.PI, degreesToRadians(progress * value) - Math.PI / 2);
      ctx.stroke();
    }

    function degreesToRadians(degrees)
    {
      return degrees * (Math.PI / 180);
    }

    function clear()
    {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    function drawText(text)
    {
      ctx.fillStyle = textColor;
      ctx.fillText(text, size, size);
    }

    function drawFrame(progress)
    {
      clear();
      drawBackground();
      drawBar();
      drawProgress(progress);
      drawText(parseInt(progress * textValue) + textSuffix);
    }


    ctx.font = textFont;
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    drawBackground();
    drawBar();
    drawFrame(0);

    if (option === "animate")
    {
      animate({
        duration: animationDuration,
        draw: function (progress) {
          drawFrame(progress);
        }
      });
    }
  };

  return this.each(function () {
    circleDiagram(this);
  });
};


(function () {
  // Initialize scrollFire random animations

  function animate(options)
  {

    let start = performance.now();

    let timing = function (timeFraction) {
      return 1 - Math.sin(Math.acos(timeFraction));
    };

    requestAnimationFrame(function animate(time) {
      // timeFraction от 0 до 1
      let timeFraction = (time - start) / options.duration;
      if (timeFraction > 1)
      {
        timeFraction = 1;
      }

      // текущее состояние анимации
      let progress = timing(timeFraction);

      options.draw(progress);

      if (timeFraction < 1)
      {
        requestAnimationFrame(animate);
      }

    });
  }

  const animations = [
    "bounce",
    "rubberBand",
    "shake",
    "tada",
    "wobble",
    "jello",
    "bounceInLeft",
    "bounceInRight",
    "fadeInRight",
    "fadeInRight",
    "flip",
    "flipInX",
    "flipInY",
    "lightSpeedIn",
    "rotateIn",
    "rotateInDownLeft",
    "rotateInDownRight",
    "rotateInUpLeft",
    "rotateInUpRight",
    "rollIn"
  ];

  const options = [];

  const selectors = [
    {
      selector: ".info-card",
      animation: "flipInX"
    },
    {
      selector: ".about .about-item:first-child",
      animation: "flipInY"
    },
    {
      selector: ".about .about-item:last-child",
      animation: "flipInX"
    },
    {
      selector: ".programmers .info-card",
      animation: "bounceInRight"
    },
    {
      selector: ".videos .video-container",
      animation: "bounceInLeft"
    },
    {
      selector: ".videos .video-link-list",
      animation: "bounceInRight"
    },
    {
      selector: ".articles .first-slide .col:nth-child(1) .article",
      animation: "flipInX"
    },
    {
      selector: ".articles .first-slide .col:nth-child(2) .article",
      animation: "flipInY"
    },
    {
      selector: ".articles .first-slide .col:nth-child(3) .article",
      animation: "flipInX"
    },
    {
      selector: ".articles .first-slide .col:nth-child(4) .article",
      animation: "flipInY"
    },
    {
      selector: ".services-small .col:nth-child(1) .service-item",
      animation: "bounceInLeft"
    },
    {
      selector: ".services-small .col:nth-child(2) .service-item",
      animation: "flipInX"
    },
    {
      selector: ".services-small .col:nth-child(3) .service-item",
      animation: "flipInX"
    },
    {
      selector: ".services-small .col:nth-child(4) .service-item",
      animation: "bounceInRight"
    },
    {
      selector: ".info-section .col",
      animation: "flipInX"
    },
    {
      selector: ".prices .promo-card:first-child",
      animation: "bounceInLeft"
    },
    {
      selector: ".prices .promo-card:last-child",
      animation: "bounceInRight"
    },
    {
      selector: ".prices .promo-card.hot",
      animation: "flip"
    },
    {
      selector: ".requisites .contacts",
      animation: "bounceInLeft"
    },
    {
      selector: ".requisites .entity-info .col",
      animation: "flipInX"
    },
  ];

  selectors.forEach(function (item) {
    $(item.selector).addClass("v-hidden");

    options.push({
      selector: item.selector,
      offset: 200,
      callback: function (el) {
        $(el)
          .removeClass("v-hidden")
          .addClass("animated")
          .addClass(item.animation);
      }
    });
  });

  options.push({
    selector: ".facts .progress-container",
    offset: 200,
    callback: function (el) {
      let valueContainer = $(el).find(".value");
      let progressContainer = $(el).find(".determinate");
      let value = parseInt($(el).attr("data-value"));
      let size = value;

      if (value > 100)
      {
        size = 100;
      }

      animate({
        duration: 1500,

        draw: function (progress) {
          progressContainer.css({
            width: progress * size + "%",
            transition: "none"
          });

          valueContainer.text(parseInt(progress * value) + "%");
        }
      });
    }
  });

  options.push({
    selector: ".circle-diagram",
    offset: 200,
    callback: function (el) {
      let container = $(el);
      let valueContainer = container.find(".value");
      let value = parseInt(container.attr("data-value"));
      let size = value;
      let startClass = "p0";

      if (value > 100)
      {
        size = 100;
      }

      animate({
        duration: 1500,
        draw: function (progress) {
          container.removeClass(startClass);
          startClass = "p" + parseInt(progress * size);
          container.addClass(startClass);
          valueContainer.text(parseInt(progress * value) + "%");
        }
      });
    }
  });

  options.push({
    selector: "canvas.circle-diagram",
    offset: 200,
    callback: function () {
      $("canvas.circle-diagram").circleDiagram("animate");
    }
  });

  $("canvas.circle-diagram").circleDiagram();

  Materialize.scrollFire(options);

})();